<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS CLIENTES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-project-diagram fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaClientes.length}}</span>
                            <br>
                            <span class="text-muted">Clientes registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de Clientes</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('008-PRO-CLI','c') == 1"  cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarCliente = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo cliente</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Clientes registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaClientes" :fields="camposClientes" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('008-PRO-CLI','u') == 1"  size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarCliente(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" v-c-tooltip.hover.click="'Cuestionarios'" @click="abrirModalCuestionarios(param)" variant="blue" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-cog my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button v-if="checkPermissions('008-PRO-CLI','d') == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCliente(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarCliente" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo cliente </span>
            </h6>
            <CButtonClose @click="modalRegistrarCliente = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarCliente)">
                <b-row>
                    <b-col lg="3">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosCliente.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="9">
                        <validation-provider name="razón social" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Razón Social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosCliente.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="n° de ruc" :rules="{required: true,numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosCliente.ruc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="representante legal" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Representante Legal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosCliente.representanteLegal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosCliente.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="email" :rules="{required: true, email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosCliente.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="dirección" v-slot="validationContext">
                            <b-form-group label="Dirección:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección" v-model="datosCliente.direccion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="página web" v-slot="validationContext">
                            <b-form-group label="Página Web:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la página web" v-model="datosCliente.paginaWeb"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="observación" v-slot="validationContext">
                            <b-form-group label="Observación:" class="mb-2">
                                <b-form-textarea v-model="datosCliente.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la observación" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarCliente = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarCliente" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar cliente</span></h6>
            <CButtonClose @click="modalActualizarCliente = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCliente)">
                <b-row>
                    <b-col lg="3">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el código" v-model="datosActualizarCliente.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="9">
                        <validation-provider name="razón social" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Razón Social:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la razón social" v-model="datosActualizarCliente.razonSocial"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="n° de ruc" :rules="{required: true,numeric:true,max:11}" v-slot="validationContext">
                            <b-form-group label="N° de ruc:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el ruc" v-model="datosActualizarCliente.ruc"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="representante legal" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Representante Legal:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el representante legal" v-model="datosActualizarCliente.representanteLegal"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" :rules="{numeric:true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarCliente.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="email" :rules="{required: true, email:true}" v-slot="validationContext">
                            <b-form-group label="Email:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el email" v-model="datosActualizarCliente.email"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="6">
                        <validation-provider name="dirección" v-slot="validationContext">
                            <b-form-group label="Dirección:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la dirección" v-model="datosActualizarCliente.direccion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="página web" v-slot="validationContext">
                            <b-form-group label="Página Web:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la página web" v-model="datosActualizarCliente.paginaWeb"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="observación" v-slot="validationContext">
                            <b-form-group label="Observación:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarCliente.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese la observación" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarCliente = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :show.sync="modalCuestionarios" :closeOnBackdrop="false" color="primario" size="lg">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Cuestionarios</span></h6>
            <CButtonClose @click="modalCuestionarios = false" class="text-white" />
        </template>
        <b-row>
            <b-col md="12">

                <b-table bordered :items="listaSatisfaccionClientes" :fields="camposCuestionarios" class="table-custom mt-2" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                    <template v-slot:cell(fechaCompletado)="data">
                        <router-link v-if="data.item.fechaCompletado != null" style="text-decoration:none;" :to="{name:'Gestion Satisfaccion Clientes', params: {id: data.item.idSatisfaccionCliente }}">
                            <li class="fas fa-share fa-sm mr-1"></li> {{data.value}}
                        </router-link>
                    </template>
                </b-table>
            </b-col>
            <b-col lg="12" class="text-right my-2">
                <b-button variant="danger" @click="modalCuestionarios = false">
                    Cerrar
                </b-button>
            </b-col>
        </b-row>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            listaClientes: [],
            camposClientes: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Código del cliente",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Razón Social",
                    class: "text-center",
                },
                {
                    key: "email",
                    label: "Correo",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosCliente: {
                idCliente: '',
                codigo: '',
                ruc: '',
                razonSocial: '',
                telefono: '',
                email: '',
                representanteLegal: '',
                direccion: '',
                paginaWeb: '',
                observacion: '',
            },
            datosActualizarCliente: {
                idClienteProyecto: '',
                idCliente: '',
                codigo: '',
                ruc: '',
                razonSocial: '',
                telefono: '',
                email: '',
                representanteLegal: '',
                direccion: '',
                paginaWeb: '',
                observacion: '',
            },
            modalRegistrarCliente: false,
            modalActualizarCliente: false,

            camposCuestionarios: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "fechaEnviado",
                    label: "Envíos de cuestionario",
                    class: "text-center",
                }, {
                    key: "fechaCompletado",
                    label: "Cuestionarios completados",
                    class: "text-center",
                },
            ],

            listaSatisfaccionClientes: [],
            listaSatisfaccion: [],
            modalCuestionarios: false,
            rutaRazonSocial: '',
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        abrirModalCuestionarios(param) {
            this.listaSatisfaccionClientes = this.listaSatisfaccion.filter(x => x.idClienteProyecto == param.item.idClienteProyecto);
            this.rutaRazonSocial = param.item.razonSocial;
            this.modalCuestionarios = true;
        },

        listarCuestionariosCompletados() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-satisfaccion-clientes-completados", {
                        params: {
                            idCliente: me.datosCliente.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaSatisfaccion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-clientes-proyectos", {
                        params: {
                            idCliente: me.datosCliente.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaClientes = response.data;
                    me.filasTotales = me.listaClientes.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarCliente() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-cliente-proyecto",
                    me.datosCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarCliente = false;
                    me.listarClientes();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarCliente() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-cliente-proyecto",
                    me.datosActualizarCliente, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarCliente = false;
                    me.listarClientes();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        eliminarCliente(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el cliente?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-cliente-proyecto", {
                                idClienteProyecto: param.item.idClienteProyecto
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarClientes();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarCliente(param) {

            this.datosActualizarCliente.idClienteProyecto = param.item.idClienteProyecto;
            this.datosActualizarCliente.idCliente = param.item.idCliente;
            this.datosActualizarCliente.codigo = param.item.codigo;
            this.datosActualizarCliente.ruc = param.item.ruc;
            this.datosActualizarCliente.razonSocial = param.item.razonSocial;
            this.datosActualizarCliente.telefono = param.item.telefono;
            this.datosActualizarCliente.email = param.item.email;
            this.datosActualizarCliente.representanteLegal = param.item.representanteLegal;
            this.datosActualizarCliente.direccion = param.item.direccion;
            this.datosActualizarCliente.paginaWeb = param.item.paginaWeb;
            this.datosActualizarCliente.observacion = param.item.observacion;
            this.modalActualizarCliente = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarCliente() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosCliente.codigo = '';

            this.datosCliente.ruc = '';
            this.datosCliente.razonSocial = '';
            this.datosCliente.telefono = '';
            this.datosCliente.email = '';
            this.datosCliente.representanteLegal = '';
            this.datosCliente.direccion = '';
            this.datosCliente.paginaWeb = '';
            this.datosCliente.observacion = '';
        },
        resetModalActualizarCliente() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {

    },
    watch: {
        modalRegistrarCliente: function (val) {
            if (val == false) {
                this.resetModalRegistrarCliente();
            }
        },
        modalActualizarCliente: function (val) {
            if (val == false) {
                this.resetModalActualizarCliente();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosCliente.idCliente = this.datosActualizarCliente.idCliente = user.uidClient;
            this.listarClientes();
            this.listarCuestionariosCompletados();
        }
    }

}
</script>
